import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfirmationConfig } from '@fuse/services/confirmation/confirmation.types';
import { FuseConfirmationDialogComponent } from '@fuse/services/confirmation/dialog/dialog.component';
import { merge } from 'lodash-es';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FuseConfirmationService {
	private _matDialog: MatDialog = inject(MatDialog);
	

	private _defaultConfig: FuseConfirmationConfig = {
		actions: {
			confirm: {
				show: true,
				label: 'Aceptar',
				color: 'accent',
			},
			cancel: {
				show: true,
				label: 'Cancelar',
			},
		},
		dismissible: true,
	};


	private _infoConfig: FuseConfirmationConfig = {
		icon: {
			show: true,
			name: 'heroicons_outline:information-circle',
			color: 'info',
		},
		actions: {
			confirm: {
				show: true,
				label: 'Aceptar',
				color: 'accent',
			},
		},
		dismissible: false,
	};

	private _questionConfig: FuseConfirmationConfig = {
		icon: {
			show: true,
			name: 'heroicons_outline:question-mark-circle',
			color: 'accent',
		},
		actions: {
			confirm: {
				show: true,
				label: 'Aceptar',
				color: 'accent',
			},
			cancel: {
				show: true,
				label: 'Cancelar',
			},
		},
		dismissible: false,
	};

	private _warnConfig: FuseConfirmationConfig = {
		icon: {
			show: true,
			name: 'heroicons_outline:exclamation-triangle',
			color: 'warn',
		},
		actions: {
			confirm: {
				show: true,
				label: 'Aceptar',
				color: 'warn',
			},
			cancel: {
				show: true,
				label: 'Cancelar',
			},
		},
		dismissible: false,
	};


	private _errorConfig: FuseConfirmationConfig = {
		icon: {
			show: true,
			name: 'heroicons_outline:x-circle',
			color: 'warn',
		},
		actions: {
			confirm: {
				show: true,
				label: 'Aceptar',
				color: 'warn',
			}
		},
		dismissible: false,
	};


	private _successConfig: FuseConfirmationConfig = {
		icon: {
			show: true,
			name: 'mat_outline:check_circle',
			color: 'success',
		},
		actions: {
			confirm: {
				show: true,
				label: 'Aceptar',
				color: 'accent',
			},
		},
		dismissible: false,
	};

	
	private _configs = {
		default: this._defaultConfig,
		info: this._infoConfig,
		question: this._questionConfig,
		warn: this._warnConfig,
		error: this._errorConfig,
		success: this._successConfig,
	}


	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	open(config: FuseConfirmationConfig, type: 'info' | 'warn' | 'error' | 'success' | 'question' |'default' ): Observable<boolean> {

		// Merge the user config with the default config
		const userConfig = merge({}, config, this._configs[type]);

		// Open the dialog
		const result = this._matDialog.open(FuseConfirmationDialogComponent, {
			autoFocus: false,
			disableClose: !userConfig.dismissible,
			data: userConfig,
			panelClass: 'fuse-confirmation-dialog-panel',
		});

		return result.afterClosed();
	}


	openBasic(title: string, message: string, type: 'info' | 'warn' | 'error' | 'success' | 'question' |'default') {
		return this.open({title, message},type);
	}
}
