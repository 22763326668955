import { Routes } from "@angular/router";
import { UnauthenticatedRouting } from "./routes/unauthenticated.routing";
import { AuthenticatedRouting } from "./routes/authenticated.routing";
import { ForgotPasswordRouting } from "./forgot-password/forgot-password.routing";

export const AUTH_ROUTES: Routes = [

	// Auth routes for authenticated users	
	AuthenticatedRouting.route,

	// Auth routes for guests
	UnauthenticatedRouting.route,
];
