import { Route } from '@angular/router';
import { DataRouter } from '../../../../shared/interfaces/data-router.interface';
import { AuthGuard } from '../../../../core/auth/guards/auth.guard';

export class ClinicMonitoringRouting {
	public static readonly data:DataRouter = {
		path: 'seguimiento-clinico',
		title: 'Seguimiento Clínico',
		label: 'Seguimiento Clínico'
	};

	public static readonly route: Route = {
		path: ClinicMonitoringRouting.data.path,
		data: ClinicMonitoringRouting.data,
		canActivateChild: [AuthGuard],
		canActivate: [AuthGuard],
		loadChildren: () => import('./clinic-monitoring.routes'),
	};
}
