import { Route } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AuthGuard } from '../../../core/auth/guards/auth.guard';
import { LayoutComponent } from '../../../layout/layout.component';
import { DataRouter } from '../../../shared/interfaces/data-router.interface';
import { SingOutRouting } from '../sign-out/sign-out.routing';


export class AuthenticatedRouting {
	public static readonly data: DataRouter = {
		path: '',
		title: environment.appName,
		layout: 'empty'
	};

	public static readonly route: Route = {
        path: AuthenticatedRouting.data.path,
        data: AuthenticatedRouting.data,
        component: LayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            SingOutRouting.route,
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes')}
        ]
    };
}
