import { Route } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { DataRouter } from '../../../shared/interfaces/data-router.interface';


export class ForgotPasswordRouting {
	public static readonly data: DataRouter = {
		path: 'forgot-password',
		title: environment.appName,
	};

	public static readonly route: Route = { 
		path: ForgotPasswordRouting.data.path, 
		data: ForgotPasswordRouting.data,
		loadChildren: () => import('./forgot-password.routes') 
	};
}
