export const UserGuard = () => {
	if (!localStorage.getItem('_T:Wequi'))
		return true;

	if (!localStorage.getItem('_M:Wequi'))
		return true;

	if (!localStorage.getItem('_U:Wequi'))
		return true;

	return !localStorage.getItem('_O:Wequi');
}


export const UserAgreementsGuard = () => {
	return !localStorage.getItem('_A:Wequi') || localStorage.getItem('_A:Wequi') == 'false' || localStorage.getItem('_A:Wequi') == 'undefined';
}
