<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
	class="dark bg-primary-900 print:hidden"
	[appearance]="navigationAppearance"
	[mode]="isScreenSmall ? 'over' : 'side'"
	[name]="'mainNavigation'"
	[navigation]="navigation.default"
	[opened]="!isScreenSmall">
	<!-- Navigation header hook -->
	<ng-container fuseVerticalNavigationHeader>
		<!-- Logo -->
		<div class="flex justify-center w-full mt-4 mb-6">
			<div (click)="goHome()"  class="navigation-logo shadow-md shadow-primary-600 cursor-pointer">
				<img src="../../../../../assets/images/logo/logo.png" alt="Logo image">
			</div>
		</div>
	</ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

	<!-- Header -->
	<div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
		<div class="flex items-center pr-2 space-x-2">
			<!-- Navigation toggle button -->
			<button
				mat-icon-button
				(click)="toggleNavigation('mainNavigation')">
				<mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
			</button>
			<!-- Navigation appearance toggle button -->
			<button
				class="hidden md:inline-flex"
				mat-icon-button
				(click)="toggleNavigationAppearance()">
				<mat-icon [svgIcon]="'heroicons_outline:arrows-right-left'"></mat-icon>
			</button>
		</div>

		<!-- Components -->
		<div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
			<fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
			<fuse-new-window class="hidden md:block"></fuse-new-window>
			<!-- <search [appearance]="'bar'"></search> -->
			<user-nav></user-nav>
		</div>
	</div>

	<!-- Content -->
	<div class="flex flex-col flex-auto">
		<!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
		<router-outlet *ngIf="true"></router-outlet>
	</div>

	<!-- Footer -->
	<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
		<div class="font-medium text-secondary">
			&copy; {{currentYear}} Smart Weiqui todos los derechos reservados
		</div>

		<div class="text-sm text-secondary ml-auto">
			{{release}}
		</div>
	</div>

</div>
