
import { Route } from '@angular/router';

import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { DataRouter } from 'app/shared/interfaces/data-router.interface';

export class AppointmentsHistoryRouting {
	public static readonly data: DataRouter = {
		path: 'historial-citas',
		title: 'Historial de Citas',
		label: 'Historial de Citas'
	};

	public static readonly route: Route = {
		path: AppointmentsHistoryRouting.data.path,
		data: AppointmentsHistoryRouting.data,
		canActivateChild: [AuthGuard],
		canActivate: [AuthGuard],
		loadChildren: () => import('./appointments-history.module').then(m => m.AppointmentsHistoryModule),
	};
}
