import { Route } from '@angular/router';
import { NoAuthGuard } from '../../../core/auth/guards/noAuth.guard';
import { LayoutComponent } from '../../../layout/layout.component';
import { DataRouter } from '../../../shared/interfaces/data-router.interface';
import { environment } from '../../../../environments/environment';
import { SingInRouting } from '../sign-in/sign-in.routing';
import { ForgotPasswordRouting } from '../forgot-password/forgot-password.routing';


export class UnauthenticatedRouting {
	public static readonly data: DataRouter = {
		path: '',
		title: environment.appName,
		layout: 'empty',
	};

	public static readonly route: Route = {
		path: UnauthenticatedRouting.data.path,
		data: UnauthenticatedRouting.data,
		component: LayoutComponent,

		canActivate: [NoAuthGuard],
		canActivateChild: [NoAuthGuard],

		children: [
			SingInRouting.route,
			ForgotPasswordRouting.route,
			{ path: '**', pathMatch: 'full', redirectTo: SingInRouting.route.path },
		]
	};
}
