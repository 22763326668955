import { Route } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { DataRouter } from '../../../shared/interfaces/data-router.interface';


export class SingInRouting {
	public static readonly data: DataRouter = {
		path: 'sign-in',
		title: environment.appName,
	};

	public static readonly route: Route = { 
		path: SingInRouting.data.path, 
		data: SingInRouting.data,
		loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes') 
	};
}
