import { inject } from '@angular/core';
import { StorageService } from 'app/core/auth/services/storage.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { forkJoin } from 'rxjs';

export const initialDataResolver = () =>
{
    const navigationService = inject(NavigationService);
    const strgSrv = inject(StorageService);
	
    // Fork join multiple API endpoint calls to wait all of them to finish
    return forkJoin([
        navigationService.get(),
        strgSrv.user(),
    ]);
};
