import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SingInRouting } from '../../../modules/auth/sign-in/sign-in.routing';
import { User } from 'app/shared/interfaces/user.interface';
import { UserService } from 'app/core/user/user.service';
import { of } from 'rxjs';


@Injectable({
	providedIn: 'root'
})
export class StorageService {

	
	constructor(
		private _router: Router,
		private _userSrv: UserService,
	) {
	}

	public starListener() {
		window.addEventListener("storage", this.storageEventListener.bind(this));
	}

	public stopListener() {
		window.removeEventListener("storage", this.storageEventListener.bind(this));
	}

	private storageEventListener(event: StorageEvent) {
		if (event.storageArea == localStorage) {
			
			if(event.key != '_T:Wequi') {
				return;
			}
			
			if(event.oldValue != event.newValue) {
				this._router.navigate([SingInRouting.data.path]);
			}
		}
	}


	public user() {
		const user = <User>JSON.parse(localStorage.getItem('_U:Wequi'));
		this._userSrv.user = user;

		return of(user);
	}
}
