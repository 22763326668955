import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/services/auth.service';
import { of } from 'rxjs';
import { AgreementsRouting } from '../../../modules/agreements/agreements.routing';
import { SingInRouting } from '../../../modules/auth/sign-in/sign-in.routing';
import { RouteGuard } from './route.guard';
import { UserAgreementsGuard, UserGuard } from './user.guard';

let numTries = 0;

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route) => {

	if (UserGuard()) {
		return inject(Router).createUrlTree([SingInRouting.data.path]);
	}

	if(UserAgreementsGuard()){
		return inject(Router).createUrlTree([AgreementsRouting.data.path]);
	}

	const isValid = RouteGuard(route.routeConfig.path);

	if(!isValid) {
		numTries++;
	}

	if(numTries == 10) {
		numTries = 0;
		inject(AuthService).signOut();
	}

	return of(isValid);
};
