import { Route } from '@angular/router';
import { AUTH_ROUTES } from './modules/auth/auth.routes';
import { CoreRouting } from './modules/core/core.routing';
import { AgreementsRouting } from './modules/agreements/agreements.routing';


// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
	AgreementsRouting.route,
	
	CoreRouting.route,


	// Auth routes
	...AUTH_ROUTES,

];
