import { Route } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { DataRouter } from '../../../shared/interfaces/data-router.interface';


export class SingOutRouting {
	public static readonly data: DataRouter = {
		path: 'sign-out',
		title: environment.appName
	};

	public static readonly route: Route = { 
		path: SingOutRouting.data.path, 
		data: SingOutRouting.data,
		loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes') 
	};
}
