import { Route } from '@angular/router';
import { DataRouter } from '../../shared/interfaces/data-router.interface';
import { LayoutComponent } from '../../layout/layout.component';

export class AgreementsRouting {
	public static readonly data: DataRouter = {
		path: 'agreements',
		layout: 'empty'
	};

	public static readonly route: Route = { 
		path: AgreementsRouting.data.path, 
		data: AgreementsRouting.data,
		component: LayoutComponent,
		loadChildren: () => import('./agreements.routes') 
	};
}
