import { Route } from '@angular/router';
import { DataRouter } from '../../shared/interfaces/data-router.interface';
import { LayoutComponent } from '../../layout/layout.component';
import { AuthGuard } from '../../core/auth/guards/auth.guard';
import { initialDataResolver } from '../../app.resolvers';

export class CoreRouting {

	public static readonly data: DataRouter = {
		path: '',
		layout: 'dense'
	};

	public static readonly route: Route = {
		path: CoreRouting.data.path,
		data: CoreRouting.data,
		component: LayoutComponent,
		canActivateChild: [AuthGuard],
		canActivate: [AuthGuard],
		resolve: {
			initialData: initialDataResolver
		},
		loadChildren: () => import('./core.module').then(m => m.CoreModule)
	};
}
