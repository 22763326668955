<!-- Button -->
<button
    mat-icon-button
    [matTooltip]="'Nueva Pantalla'"
    (click)="openNewWindow()">
    <ng-container [ngTemplateOutlet]="iconTpl || defaultIconTpl"></ng-container>
</button>

<!-- Default icon -->
<ng-template #defaultIconTpl>
    <mat-icon [svgIcon]="'heroicons_solid:arrow-top-right-on-square'"></mat-icon>
</ng-template>
