import { Route } from '@angular/router';
import { DataRouter } from '../../../../shared/interfaces/data-router.interface';
import { AuthGuard } from '../../../../core/auth/guards/auth.guard';

export class ClinicSerumTherapyRouting {
	public static readonly data: DataRouter = {
		path: 'serum-therapy',
		title: 'Sueroterapia',
		label: 'Sueroterapia'
	};

	public static readonly route: Route = {
		path: ClinicSerumTherapyRouting.data.path,
		data: ClinicSerumTherapyRouting.data,
		canActivateChild: [AuthGuard],
		canActivate: [AuthGuard],
		loadChildren: () => import('./clinic-serum-therapy.routes'),
	};
}
