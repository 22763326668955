import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
    selector       : 'fuse-new-window',
    templateUrl    : './new-window.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'fuseFullscreen',
    standalone     : true,
    imports        : [MatButtonModule, MatTooltipModule, NgTemplateOutlet, MatIconModule],
})
export class FuseNewWindowComponent
{
    @Input() iconTpl: TemplateRef<any>;
    @Input() tooltip: string;

    /**
     * Constructor
     */
    constructor(
		private _route: Router
	)
    {
    }

    
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle the fullscreen mode
     */
    openNewWindow(): void
    {
		window.open(environment.weiqui)?.focus();
    }
}
