<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img alt=""
            class="w-7 h-7 rounded-full"
            *ngIf="showAvatar && user.avatar"
            [src]="user.avatar">
        <mat-icon
            *ngIf="!showAvatar || !user.avatar"
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu"
	class="py-0 my-0">
	<div class="flex items-center cursor-default bg-primary-100 h-10 pl-1.5">
        <span class="text-md font-semibold text-primary-600">{{user.name}}</span>
    </div>
    
	<mat-divider class="mb-2 mt-0"></mat-divider>
    
	<button mat-menu-item [routerLink]="['', 'perfil']" >
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Mi Perfil</span>
    </button>

    <mat-divider class="my-2"></mat-divider>

    <button class="mb-2"
        mat-menu-item
        (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
        <span>Salir</span>
    </button>
</mat-menu>
