
import { Injectable } from '@angular/core';
import { Navigation } from 'app/core/navigation/interfaces/navigation.interface';
import { Observable, ReplaySubject, of } from 'rxjs';
import { FuseNavigationItem } from '../../../@fuse/components/navigation';
import { NavigationResponse } from './interfaces/navigation-response.interface';

@Injectable({ providedIn: 'root' })
export class NavigationService {
	private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
	private _userMenu: NavigationResponse;

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Getter for navigation
	 */
	get navigation$(): Observable<Navigation> {
		return this._navigation.asObservable();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Get all navigation data
	 */
	get(): Observable<Navigation> {

		const menu = <NavigationResponse[]>JSON.parse(localStorage.getItem('_M:Wequi') ?? '[]');
		this._userMenu = menu[0];

		menu.shift();

		const navItems = this._buildMenu(menu);

		const navigation: Navigation = {
			compact: navItems,
			default: navItems,
			futuristic: navItems,
			horizontal: navItems,
		}

		this._navigation.next(navigation);

		return of(navigation);
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Private methods
	// -----------------------------------------------------------------------------------------------------
	private _buildMenu(menu: NavigationResponse[]) {
		if (!menu) {
			return;
		}

		menu.sort((a, b) => a.order - b.order);

		const navigationitems: FuseNavigationItem[] = menu.map(
			nav => (
				{
					id: nav.id,
					title: nav.name,
					type: nav.children ? 'group' : 'basic',
					link: nav.url?.join('/') ?? undefined,
					icon: nav.icon,
					children: this._buildMenu(nav.children),
				}
			)
		);

		return navigationitems;
	}
}
