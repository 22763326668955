import { HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { LoaderService } from '../../shared/modules/loader/services/loader.service';
import { Observable, firstValueFrom, tap } from 'rxjs';
import { AuthService } from 'app/core/auth/services/auth.service';
import { Router } from '@angular/router';
import { SingInRouting } from 'app/modules/auth/sign-in/sign-in.routing';



/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
	const loaderSrv = inject(LoaderService);
	const router = inject(Router);
	const authGuard = inject(AuthService);

	if (!loaderSrv.isManual) {
		loaderSrv.show = true;
	}

	let newReq = req.clone();

	if (localStorage.getItem('_T:Wequi') != null) {
		newReq = req.clone({
			setHeaders: {
				'Authorization': 'bearer ' + localStorage.getItem('_T:Wequi')
			}
		});
	}


	// Response
	return next(newReq).pipe(
		tap({
			next: (event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {

					if (!loaderSrv.isManual) {
						loaderSrv.show = false;
					}
				}
			},
			error: (error) => {
				if (!loaderSrv.isManual) {
					loaderSrv.show = false;
				}

				if (error.status == 403) {
					firstValueFrom(authGuard.signOut()).then(() => {
						router.navigate([SingInRouting.data.path])
					});
				}
			}
		})
	);
};
