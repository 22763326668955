import { UntypedFormBuilder, Validators } from "@angular/forms";

const fb = new UntypedFormBuilder();

export const environment = {
	appName: 'Smart Weiqui',
	weiqui: "https://weiqui.com/#/admin",
	weiquiApi: "https://core.weiqui.com",
	loginApi: "https://idp.weiqui.com",
	apiversion: "v1",

	release: 'v3.0.32',
	user: null,
	password: null,

	loginForm: fb.group({
		user: ['', [Validators.required, Validators.email]],
		password: ['', Validators.required],
	})
}
