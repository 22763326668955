import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { Service } from '../../shared/services/service';
import { User } from '../../shared/interfaces/user.interface';

@Injectable({ providedIn: 'root' })
export class UserService extends Service {
	private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

	/**
	 * Constructor
	 */
	constructor(private _httpClient: HttpClient) {
		super();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Setter & getter for user
	 *
	 * @param value
	 */
	set user(value: User) {
		// Store the value
		this._user.next(value);
	}

	get user$(): Observable<User> {
		return this._user.asObservable();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Get the current logged in user data
	 */
	get(): Observable<{user:User}> {
		return this._httpClient.get<{user:User}>(`${this.weiquiApi}/${this.version}/user`,).pipe(
			tap((result) => {
				this._user.next(result.user);
			}),
		);
	}

	
	public changePassword(paswords: any) {

		let params = new HttpParams()
			.set("oldPassword", paswords.oldPassword)
			.set("password", paswords.password);

		return this._httpClient.patch<User>(
			`${this.weiquiApi}/${this.version}/user/profile`,
			params,
		);
	}


	public update(user: User) {

		const params = new HttpParams()
			.set("identificationType", user.identificationType)
			.set("identification", user.identification)
			.set("title", '')
			.set("name", user.name)
			.set("email", user.email)
			.set("colorAgenda", user.colorAgenda);


		return this._httpClient.patch<User>(
			`${this.weiquiApi}/${this.version}/user/profile`,
			params,
		);
	}
}
