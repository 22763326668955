import { Route } from '@angular/router';

import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { DataRouter } from 'app/shared/interfaces/data-router.interface';


export class ClinicHistoryRouting {
	public static readonly data:DataRouter = {
		path: 'historia-clinica',
		title: 'Historia Clínica',
		label: 'Historia Clínica'
	};

	public static readonly route: Route = {
		path: ClinicHistoryRouting.data.path,
		data: ClinicHistoryRouting.data,
		canActivateChild: [AuthGuard],
		canActivate: [AuthGuard],
		loadChildren: () => import('./clinic-history.routes')
	};
}
