import { SerumsRouting } from './../../../modules/core/admin/serums/serums.routing';
import { ClinicSerumTherapyRouting } from './../../../modules/core/clinic-task/clinic-serum-therapy/clinic-serum-therapy.routing';
import { ClinicMonitoringRouting } from '../../../modules/core/clinic-task/clinic-monitoring/clinic-monitoring.routing';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { catchError, Observable, of, switchMap, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Service } from '../../../shared/services/service';
import { SingInResponse } from '../interfaces/login-response.interface';
import { UserAuth } from '../interfaces/user-auth.interface';
import { StorageService } from './storage.service';
import { NavigationResponse } from '../../navigation/interfaces/navigation-response.interface';
import { ClinicHistoryRouting } from 'app/modules/core/clinic-task/clinic-history/clinic-history.routing';
import { ClinicEstheticRouting } from 'app/modules/core/clinic-task/clinic-esthetic/clinic-esthetic.routing';
import { User } from 'app/shared/interfaces/user.interface';
import { AppointmentsHistoryRouting } from 'app/modules/core/reports/appointments-history/appointments-history.routing';
import { AgreementsRouting } from '../../../modules/agreements/agreements.routing';


@Injectable({ providedIn: 'root' })
export class AuthService extends Service {
	private _authenticated: boolean = false;
	private _loginApi: string = environment.loginApi;

	/**
	 * Constructor
	 */
	constructor(
		private _httpClient: HttpClient,
		private _userService: UserService,
		private _strService: StorageService,
		private _userSrv: UserService,
	) {
		super();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Setter & getter for access token
	 */
	set accessToken(token: string) {
		localStorage.setItem('accessToken', token);
	}

	get accessToken(): string {
		return localStorage.getItem('accessToken') ?? '';
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Forgot password
	 *
	 * @param email
	 */
	forgotPassword(email: string): Observable<any> {
		return this._httpClient.post(`${this.weiquiApi}/${this.version}/security/recovery/password`, { email });
	}

	/**
	 * Reset password
	 *
	 * @param password
	 */
	resetPassword(password: string): Observable<any> {
		return this._httpClient.post('api/auth/reset-password', password);
	}

	/**
	 * Sign in
	 * @param userAuth Credentials
	 * @returns SignInResponse
	 */
	signIn(userAuth: UserAuth): Observable<SingInResponse> {
		const headers = new HttpHeaders()
			.set("Content-Type", 'application/json');


		return this._httpClient.post<any>(
			`${this.loginApi}/login`,
			userAuth,
			{ headers },
		).pipe(
			tap(
				(info: SingInResponse) => {
					const commonOpts = [
						'',
						'sign-out',
						ClinicHistoryRouting.data.path,
						ClinicMonitoringRouting.data.path,
						ClinicEstheticRouting.data.path,
						ClinicSerumTherapyRouting.data.path,
						AppointmentsHistoryRouting.data.path,
						AgreementsRouting.data.path,

						':id',
						'add',
						'new',
						'details',
					];

					const opts = this._avaibleOpts(info.menu, []);

					opts.unshift(...commonOpts);

					this._userSrv.user = info.user;

					localStorage.setItem('_T:Wequi', info.token);
					localStorage.setItem('_M:Wequi', JSON.stringify(info.menu));
					localStorage.setItem('_U:Wequi', JSON.stringify(info.user));
					localStorage.setItem('_O:Wequi', JSON.stringify(opts));
					localStorage.setItem('_A:Wequi', JSON.stringify(info.user.acceptedAgreements));
				}
			)
		);
	}

	/**
	 * Sign in using the access token
	 */
	signInUsingToken(): Observable<any> {
		// Sign in using the token
		return this._httpClient.post('api/auth/sign-in-with-token', {
			accessToken: this.accessToken,
		}).pipe(
			catchError(() =>

				// Return false
				of(false),
			),
			switchMap((response: any) => {
				// Replace the access token with the new one if it's available on
				// the response object.
				//
				// This is an added optional step for better security. Once you sign
				// in using the token, you should generate a new one on the server
				// side and attach it to the response object. Then the following
				// piece of code can replace the token with the refreshed one.
				if (response.accessToken) {
					this.accessToken = response.accessToken;
				}

				// Set the authenticated flag to true
				this._authenticated = true;

				// Store the user on the user service
				this._userService.user = response.user;

				// Return true
				return of(true);
			}),
		);
	}

	/**
	 * Sign out
	 */
	signOut(): Observable<any> {
		localStorage.removeItem('_T:Wequi');
		localStorage.removeItem('_M:Wequi');
		localStorage.removeItem('_U:Wequi');
		localStorage.removeItem('_O:Wequi');
		localStorage.removeItem('_A:Wequi');

		this._strService.stopListener();

		// Return the observable
		return of(true);
	}

	/**
	 * Sign up
	 *
	 * @param user
	 */
	signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
		return this._httpClient.post('api/auth/sign-up', user);
	}

	/**
	 * Unlock session
	 *
	 * @param credentials
	 */
	unlockSession(credentials: { email: string; password: string }): Observable<any> {
		return this._httpClient.post('api/auth/unlock-session', credentials);
	}

	/**
	 * Check the authentication status
	 */
	check(): Observable<boolean> {
		// Check if the user is logged in
		if (this._authenticated) {
			return of(true);
		}

		// Check the access token availability
		if (!this.accessToken) {
			return of(false);
		}

		// Check the access token expire date
		if (AuthUtils.isTokenExpired(this.accessToken)) {
			return of(false);
		}

		// If the access token exists, and it didn't expire, sign in using it
		return this.signInUsingToken();
	}

	public accepteAgreements() {
		return this._httpClient.post<any>(
			`${this.weiquiApi}/${this.version}/user/agreement`,
			{}
		);
	}

	/**
	 * Check the available options
	 * @param menu menu properties
	 * @param opts options avaibles
	 */
	private _avaibleOpts(menu: NavigationResponse[], opts: string[]) {

		menu.forEach(
			(opt) => {

				if (opt.url) {
					opts.push(...opt.url);
				}

				if (opt.children) {
					this._avaibleOpts(opt.children, opts);
				}
			}
		);

		return opts
	}
}
