import { Route } from '@angular/router';
import { DataRouter } from '../../../../shared/interfaces/data-router.interface';
import { AuthGuard } from '../../../../core/auth/guards/auth.guard';

export class ClinicEstheticRouting {
	public static readonly data: DataRouter = {
		path: 'cita-estetica',
		title: 'Cita de Estética',
		label: 'Cita de Estética'
	};

	public static readonly route: Route = {
		path: ClinicEstheticRouting.data.path,
		data: ClinicEstheticRouting.data,
		canActivateChild: [AuthGuard],
		canActivate: [AuthGuard],
		loadChildren: () => import('./clinic-esthetic.routes'),
	};
}
